<template>
  <div>
    <div class="print-form" v-for="pasien in pasienList" :key="pasien.id">
      <v-container class="patient-info">
        <v-row class="no-gutters">
          <v-col cols="1">
            <img src="@/assets/logo.png" width="110px" />
          </v-col>
          <v-col cols="8" offset="1" class="header-text">
            <h5>LABORATORIUM EXCELLENT BENEFICIAL DIAGNOSTIC CENTER (EBDC)</h5>
            <h6>
              Jl. Prof. DR. Soepomo No.58, RT.5/RW.1, Menteng Dalam, Kec. Tebet,
              Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870
            </h6>
            <h6>
              Phone : 021 - 2290 5222 | Email:
              labebdc.indonesia@gmail.com/info@ebdc-lab.id
            </h6>
          </v-col>
          <v-col cols="1">&nbsp;</v-col>
        </v-row>
        <v-row class="no-gutters mt-2">
          <v-col><v-divider></v-divider></v-col>
        </v-row>

        <!-- Informasi Laboratorium -->
        <v-row class="no-gutters mt-3">
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="20%">
                      Laboratorium/ <br />
                      <i>Laboratory</i>
                    </td>
                    <td width="30%">
                      {{ pasien.location }}
                      <br />
                      Kode Lab: C.457
                    </td>
                    <td width="30%">No. Request Lab</td>
                    <td width="20%">{{ pasien.requestLab || "-" }}</td>
                  </tr>
                  <tr>
                    <td>Izin Pengoperasian<br />Lab.RT-PCR</td>
                    <td>SR.01.07 / II / 5392 / 2020</td>
                    <td>
                      Pengambilan Spesimen/<br />
                      <i>Sampling Date</i>
                    </td>
                    <td>{{ localDate(pasien.samplingDate) }}</td>
                  </tr>
                  <tr>
                    <td>Penanggung Jawab/ <br /><i>Doctor in Charge</i></td>
                    <td>Dr. dr. Budiman Bela, Sp.MK(K)</td>
                    <td>
                      Tanggal Penerimaan Sampel/
                      <i>Sample Receive Date</i>
                    </td>
                    <td>{{ localDate(pasien.receiveSampleDate) }}</td>
                  </tr>
                  <tr>
                    <td>
                      Jenis Spesimen/ <br />
                      <i>Type of Specimen</i>
                    </td>
                    <td v-if="pasien.antigenResult != null">Nasofaring</td>
                    <td v-if="pasien.pcrResult != null">
                      Nasofaring dan Orofaring
                    </td>
                    <td>
                      Tanggal Pengeluaran Hasil/ <br />
                      <i>Result Release Date</i>
                    </td>
                    <td>{{ localDate(pasien.resultDate) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- Divider -->
        <v-row class="no-gutters">
          <v-col><v-divider class="mt-3"></v-divider></v-col>
        </v-row>

        <!-- Rincian Informasi -->
        <v-row class="no-gutters my-2">
          <v-col cols="1"> &nbsp; </v-col>
          <v-col cols="8" offset="1" class="header-text">
            <h6>
              RINCIAN INFORMASI LABORATORIUM/ <i>LABORATORY INFORMATION</i>
            </h6>
            <h6>
              Keterangan ini adalah untuk menjelaskan hasil test dari/
              <br />
              <i>This is to report the test result of this following person:</i>
            </h6>
          </v-col>
          <v-col>&nbsp;</v-col>
        </v-row>

        <!-- Informasi Laboratorium -->
        <v-row class="no-gutters mb-1">
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="20%">
                      Nama/
                      <i>Name</i>
                    </td>
                    <td colspan="3">
                      {{ pasien.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Jenis Kelamin/
                      <i>Gender</i>
                    </td>
                    <td colspan="3">
                      <i>{{ pasien.gender }}</i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kewarganegaraan/
                      <i>Nationality</i>
                    </td>
                    <td colspan="3">
                      {{ pasien.nationality }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Nomor KTP/
                      <i>Identity Number</i>
                    </td>
                    <td width="30%">
                      {{ pasien.identityNumber }}
                    </td>
                    <td width="30%">
                      Nomor Paspor/
                      <i>Passport Number</i>
                    </td>
                    <td width="20%">
                      {{ pasien.passportNumber }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Tanggal Lahir/
                      <i>Date of Birth</i>
                    </td>
                    <td colspan="3">
                      {{ localDate(pasien.birthDate) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Alamat/
                      <i>Address</i>
                    </td>
                    <td colspan="3">
                      {{ pasien.address }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- Informasi Tes -->
        <v-row class="no-gutters mb-1">
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr class="header-table">
                    <td width="20%">
                      Nama Tes/
                      <i>Test Name</i>
                    </td>
                    <td width="30%">
                      Hasil/
                      <i>Result</i>
                    </td>
                    <td width="30%">
                      Nilai CT/
                      <i>CT Value</i>
                    </td>
                    <td width="20%">
                      Referensi/
                      <i>Reference</i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span v-if="pasien.pcrResult != null"
                        >PCR SARS-CoV-2</span
                      >
                      <span v-if="pasien.antigenResult != null"
                        >ANTIGEN SARS-CoV-2</span
                      >
                    </td>
                    <td style="text-align: center">
                      <b v-if="pasien.pcrResult != null">{{
                        pasien.pcrResult
                      }}</b>
                      <b v-if="pasien.antigenResult != null">{{
                        pasien.antigenResult
                      }}</b>
                    </td>
                    <td style="text-align: center">
                      {{ pasien.ctValue || "-" }}
                    </td>
                    <td>
                      <span v-if="pasien.pcrResult != null">{{
                        reference
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- Catatan -->
        <v-row class="no-gutters">
          <v-col class="catatan">
            Catatan/Note:
            <ul>
              <li>
                Hasil tersebut menggambarkan kondisi pasien saat pengambilan
                spesimen / These results describe the patient's condition at the
                time of specimen collection
              </li>
              <li>
                Pemeriksaan PCR mendeteksi materi genetik virus tanpa dapat
                menentukan virus dalam keadaan hidup atau mati / PCR examination
                detects viral genetic material without being able to determine
                whether the virus is alive or dead
              </li>
              <li>
                Ct value dapat menggambarkan konsentrasi material genetik virus
                namun bersifat tidak mutlak (relatif), dipengaruhi oleh reagen
                yang dipakai, teknis pekerjaan, dan pengambilan spesimen / Ct
                value can describe the concentration of viral genetic material
                but it is not absolute (relative), influenced by the reagent
                used, technical work, and specimen collection
              </li>
              <li>
                Ct value harus dimaknai dengan hati-hati. Dokter yang meminta
                pemeriksaan PCR COVID-19 dapat menghubungi dokter spesialis
                mikrobiologi klinik penanggung jawab hasil, jika memerlukan
                penjelasan / Ct value should be interpreted with care. Doctors
                who request a PCR COVID-19 examination can contact a specialist
                clinical microbiologist in charge of results, if necessary
                explanation
              </li>
              <li>
                Jika Hasil Positif, segera lakukan isolasi mandiri dan hubungi
                nomor darurat 119 extensi 9 untuk mendapatkan pertolongan medis
                / If the result is positive, do self-isolation immediately and
                call emergency number 119 extension 9 to get medical help
              </li>
            </ul>
          </v-col>
        </v-row>

        <!-- QR Code & TTD -->
        <v-row class="no-gutters footer mt-3">
          <v-col cols="12">
            <i
              >* Hasil laboratorium ini telah diverifikasi secara elektronik,
              sehingga tidak diperlukan tanda tangan basah.</i
            >
          </v-col>
          <!-- <v-col cols="12"> <i>Doctor in Charge</i> </v-col> -->
          <v-col cols="12">
            <vue-qrcode
              :value="qrcode(pasien.id)"
              :scale="3.8"
              :margin="1"
              :type="'image/webp'"
            />
          </v-col>
          <!-- <v-col cols="12">
            <b>Dr. dr. Budiman Bela, Sp.MK(K)</b>
          </v-col> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      pasiens: [],
      reference: "CT value cut off (Perkin Elmer) < 42",
    };
  },
  computed: {
    pasienList() {
      return this.$store.getters.pasienListTobePrinted;
    },
  },
  methods: {
    qrcode(id) {
      let url = window.location.host;
      let encId = window.btoa(id);
      return "https://" + url + "/#" + "/qr_card/" + encId;
    },
    currentDate() {
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(
        new Date()
      );
    },
    localDate(date) {
      date = new Date(date);
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(date);
    },
    yearOld(date) {
      date = new Date(date);
      let today = new Date();
      let yearOld = today.getFullYear() - date.getFullYear();
      return yearOld;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0px;
}

.print-form {
  margin: 0%;
  padding: 0%;
  display: block;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
}

.header-text {
  text-align: center;
}

.header-table {
  font-weight: bold;
  text-align: center;
}

.table-pasien {
  height: 20px;
}

.catatan {
  font-size: 10px;
}

.footer {
  font-size: 12px;
}

table,
tr,
td {
  border: 1px solid black;
  font-size: 12px !important;
  height: 30px !important;
  padding-left: 5px !important;
  padding-top: 2px !important;
}
</style>
